import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit,
  faLayerGroup,
  faPlus,
  faSearch,
  faSignOutAlt,
  faTasks,
  faTimes,
  faFileAlt,
  faExpand,
  faCompress,
  faTrash,
  faChevronLeft,
  faFileExport,
  faTag,
  faTags,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faEdit,
  faLayerGroup,
  faPlus,
  faSearch,
  faSignOutAlt,
  faTasks,
  faTimes,
  faFileAlt,
  faExpand,
  faCompress,
  faTrash,
  faChevronLeft,
  faFileExport,
  faTag,
  faTags,
  faArrowUp,
  faArrowDown
);
